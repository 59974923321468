export default {
  methods: {
    checkInputValue(value) {
      return value.length > 0;
    },
    checkPositiveNumberValue(value) {
      const number = Number.parseInt(value);

      return !isNaN(number) && value >= 0;
    },
    checkSelectValue(value) {
      return value !== null;
    },
    checkCarValidation(value) {
      return (
        value.registrationNumber !== "" &&
        value.vin !== "" &&
        value.model !== "" &&
        value.usageStartDate !== null &&
        value.category !== null &&
        value.brand !== "" &&
        value.type !== null &&
        this.checkPositiveNumberValue(value.year) &&
        (!value.mileage || this.checkPositiveNumberValue(value.mileage) > 0) &&
        value.fuelType !== null
      );
    }
  }
};
