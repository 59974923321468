<template>
  <div>
    <b-button @click="$refs.fileInput.click()">{{ $t("import-vehicle.import-btn") }}</b-button>
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      @change="onImportVehicles"
    />
    <b-modal
      id="import-result"
      :title="$t('import-vehicle.modal.title')"
      :ok-title="$t('import-vehicle.modal.ok-title')"
      @hidden="reset"
      scrollable
      ok-only
    >
      <template v-slot:default>
        <div>{{ $t("import-vehicle.success", { count: importResult.importedVehiclesCount }) }}</div>
        <div v-if="hasErrors">
          <h6>{{ $t("import-vehicle.modal.error-list") }}</h6>
          <ul>
            <li v-for="(error, i) in importResult.errors" :key="i">{{ error }}</li>
          </ul>
        </div>
        <div v-if="hasWarnings">
          <h6>{{ $t("import-vehicle.modal.warning-list") }}</h6>
          <ul>
            <li v-for="(warning, i) in importResult.warnings" :key="i">{{ warning }}</li>
          </ul>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import eventBus from "@/services/event-bus.js";
import { VEHICLES_IMPORTED } from "@/const/events.js";

export default {
  data() {
    return this.getDefaultData();
  },
  computed: {
    hasErrors() {
      return this.importResult.errors && this.importResult.errors.length > 0;
    },
    hasWarnings() {
      return this.importResult.warnings && this.importResult.warnings.length > 0;
    }
  },
  methods: {
    ...mapActions(["vehicle/import"]),
    getDefaultData() {
      return {
        importResult: {
          errors: [],
          warnings: [],
          importedVehiclesCount: 0
        }
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },
    onImportVehicles(e) {
      let vm = new FormData();
      vm.append("file", e.target.files[0]);
      this["vehicle/import"](vm)
        .then(resp => {
          this.importResult.importedVehiclesCount = resp.data.importedCount;
          this.importResult.errors = resp.data.errors.map(x =>
            this.$t(`import-vehicle.error.${x.errorCode}`, { row: x.row })
          );
          this.importResult.warnings = resp.data.warnings.map(x =>
            this.$t(`import-vehicle.error.${x.errorCode}`, { row: x.row })
          );
          this.$bvModal.show("import-result");
          eventBus.$emit(VEHICLES_IMPORTED);
        })
        .catch(err => {
          if (err.data.errorCode) {
            alert(this.$t(`import-vehicle.error.${err.data.errorCode}`));
          } else {
            alert(this.$t("import-vehicle.error.general-error"));
          }
        })
        .finally(() => (e.target.value = ""));
    }
  }
};
</script>
