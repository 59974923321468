<template>
  <div>
    <div class="overflowable" style="display: flex; flex-direction: column">
      <div>
        <div class="d-flex mb-2 ml-1">
          <add-vehicle></add-vehicle>
          <import-vehicle></import-vehicle>
        </div>
        <vehicle-list></vehicle-list>
      </div>
    </div>
  </div>
</template>

<script>
import VehicleList from "@/components/vehicle-management/VehicleList.vue";
import ImportVehicle from "@/components/vehicle/ImportVehicle.vue";
import AddVehicle from "@/components/vehicle-management/AddVehicle.vue";

export default {
  components: {
    VehicleList,
    ImportVehicle,
    AddVehicle
  }
};
</script>
