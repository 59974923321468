<template>
  <div>
    <b-row>
      <b-col md="6" class="mb-2">
        <b-input-group size="sm">
          <b-form-checkbox v-model="showSoldVehicles" @input="onSearchParamsChanged" switch>
            {{ $t("carControl.soldCars") }}
          </b-form-checkbox>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="mb-2">
        <b-input-group size="sm">
          <b-form-checkbox v-model="showAcceptedOnly" @input="onSearchParamsChanged" switch>
            {{ $t("carControl.checked-in-only") }}
          </b-form-checkbox>
        </b-input-group>
      </b-col>
    </b-row>
    <b-pagination
      :value="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :per-page="pagination.limit"
      @input="onChangeCurrentPage($event, updateTable)"
    ></b-pagination>
    <b-table
      :items="items"
      :fields="fields"
      :busy="isLoading"
      responsive
      no-local-sorting
      @sort-changed="onSortChanged($event, updateTable)"
    >
      <template v-slot:top-row>
        <td>
          <b-input
            :value="filters.registrationNumber"
            @change="onChangeFilterValue(filters, 'registrationNumber', $event, updateTable)"
          ></b-input>
        </td>
        <td>
          <b-input :value="filters.vin" @change="onChangeFilterValue(filters, 'vin', $event, updateTable)"></b-input>
        </td>
        <td>
          <b-input
            :value="filters.brand"
            @change="onChangeFilterValue(filters, 'brand', $event, updateTable)"
          ></b-input>
        </td>
        <td>
          <b-input
            :value="filters.model"
            @change="onChangeFilterValue(filters, 'model', $event, updateTable)"
          ></b-input>
        </td>
        <td>
          <b-datepicker
            value-as-date
            reset-button
            :value="filters.usageStartDate"
            :locale="$i18n.locale"
            :label-reset-button="$t('datepicker.reset-btn')"
            @input="onChangeFilterValue(filters, 'usageStartDate', $event, updateTable)"
            placeholder=""
            label-no-date-selected=""
            boundary="window"
          ></b-datepicker>
        </td>
        <td>
          <b-datepicker
            value-as-date
            reset-button
            :value="filters.saleDate"
            :locale="$i18n.locale"
            :label-reset-button="$t('datepicker.reset-btn')"
            @input="onChangeFilterValue(filters, 'saleDate', $event, updateTable)"
            placeholder=""
            label-no-date-selected=""
            boundary="window"
          ></b-datepicker>
        </td>
        <td>
          <b-input
            :value="filters.acceptedBy"
            @change="onChangeFilterValue(filters, 'acceptedBy', $event, updateTable)"
          ></b-input>
        </td>
        <td>
          <b-datepicker
            value-as-date
            reset-button
            :value="filters.acceptedAt"
            :locale="$i18n.locale"
            :label-reset-button="$t('datepicker.reset-btn')"
            @input="onChangeFilterValue(filters, 'acceptedAt', $event, updateTable)"
            placeholder=""
            label-no-date-selected=""
            boundary="window"
          ></b-datepicker>
        </td>
        <td>
          <b-input
            :value="filters.category"
            @change="onChangeFilterValue(filters, 'category', $event, updateTable)"
          ></b-input>
        </td>
        <td>
          <b-input :value="filters.type" @change="onChangeFilterValue(filters, 'type', $event, updateTable)"></b-input>
        </td>
        <td>
          <b-input
            :value="filters.template"
            @change="onChangeFilterValue(filters, 'template', $event, updateTable)"
          ></b-input>
        </td>
      </template>
      <template v-slot:cell(category)="row">{{ row.value.title[$i18n.locale] }}</template>
      <template v-slot:cell(type)="row">{{ row.value ? row.value.title[$i18n.locale] : "" }}</template>
      <template v-slot:cell(usageStartDate)="row">
        <span v-if="row.value"> {{ $d(Date.parse(row.value)) }}</span>
      </template>
      <template v-slot:cell(saleDate)="row">
        <span v-if="row.value">{{ $d(Date.parse(row.value)) }}</span>
      </template>
      <template v-slot:cell(acceptedBy)="row">
        <span v-if="row.value">{{ row.value.userName }}</span>
      </template>
      <template v-slot:cell(acceptedAt)="row">
        <span v-if="row.value">{{ $d(Date.parse(row.value), "log") }}</span>
      </template>
      <template v-slot:cell(template)="row">{{ row.value }}</template>
      <template v-slot:cell(actions)="row">
        <b-button size="sm" @click="editVehicle(row.item.id)" class="mr-1 mb-2">
          {{ $t("carControl.edit") }}
        </b-button>
        <b-button size="sm" @click="onLoadedItemsClick(row.item.id)" class="mr-1">
          {{ $t("carControl.loaded-items") }}
        </b-button>
      </template>
    </b-table>
    <edit-vehicle ref="editVehicleControl"></edit-vehicle>
  </div>
</template>

<script>
import redirectWithChecksMixin from "@/mixins/redirect-with-check.js";
import api from "@/services/api.js";
import eventBus from "@/services/event-bus.js";
import { VEHICLE_ADDED, VEHICLES_IMPORTED, VEHICLE_EDITED, VEHICLE_DELETED } from "@/const/events.js";
import EditVehicle from "@/components/vehicle-management/EditVehicle.vue";

import paginationMixin from "@/mixins/pagination-mixin.js";

export default {
  mixins: [redirectWithChecksMixin, paginationMixin],
  components: {
    EditVehicle
  },
  data() {
    return {
      isLoading: false,
      items: [],
      filters: {
        registrationNumber: "",
        vin: "",
        brand: "",
        model: "",
        usageStartDate: null,
        saleDate: null,
        acceptedBy: "",
        acceptedAt: null,
        category: "",
        type: "",
        template: ""
      },
      showSoldVehicles: false,
      showAcceptedOnly: false
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "registrationNumber",
          label: this.$t("carControl.templateCarControl.registrationNumber"),
          sortable: true
        },
        { key: "vin", label: this.$t("carControl.templateCarControl.vin"), sortable: true },
        { key: "brand", label: this.$t("carControl.templateCarControl.brand"), sortable: true },
        { key: "model", label: this.$t("carControl.templateCarControl.model"), sortable: true },
        { key: "usageStartDate", label: this.$t("carControl.templateCarControl.usageStartDate"), sortable: true },
        { key: "saleDate", label: this.$t("carControl.templateCarControl.saleDate"), sortable: true },
        { key: "acceptedBy", label: this.$t("carControl.templateCarControl.accepted-by"), sortable: true },
        { key: "acceptedAt", label: this.$t("carControl.templateCarControl.accepted-at"), sortable: true },
        { key: "category", label: this.$t("carControl.templateCarControl.category"), sortable: true },
        { key: "type", label: this.$t("carControl.templateCarControl.type"), sortable: true },
        { key: "template", label: this.$t("carControl.templateCarControl.template"), sortable: true },
        { key: "actions", label: this.$t("carControl.actions") }
      ];
    }
  },
  methods: {
    onSearchParamsChanged() {
      this.updateTable();
    },
    editVehicle(id) {
      this.$refs.editVehicleControl.showEditControl(id);
    },
    onLoadedItemsClick(id) {
      this.$router.push({ name: "current-load", params: { vehicleId: id } });
    },
    updateTable() {
      const searchParams = {
        ...this.paginationParams,
        ...this.filters,
        usageStartDate: this.filters.usageStartDate ? this.filters.usageStartDate.toISOString() : null,
        saleDate: this.filters.saleDate ? this.filters.saleDate.toISOString() : null,
        acceptedAt: this.filters.acceptedAt ? this.filters.acceptedAt.toISOString() : null,
        soldIncluded: this.showSoldVehicles,
        acceptedOnly: this.showAcceptedOnly,
        locale: this.$i18n.locale
      };
      this.isLoading = true;
      api
        .findVehicles(searchParams)
        .then(resp => {
          this.items = resp.data.items;
          this.pagination.totalCount = resp.data.totalCount;
        })
        .catch(() => alert(this.$t("carControl.error.error-when-loading-vehicles")))
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    eventBus.$on(VEHICLE_ADDED, this.updateTable);
    eventBus.$on(VEHICLE_EDITED, this.updateTable);
    eventBus.$on(VEHICLE_DELETED, this.updateTable);
    eventBus.$on(VEHICLES_IMPORTED, this.updateTable);
    this.updateTable();
  },
  beforeDestroy() {
    eventBus.$off(VEHICLE_ADDED, this.updateTable);
    eventBus.$off(VEHICLE_EDITED, this.updateTable);
    eventBus.$off(VEHICLE_DELETED, this.updateTable);
    eventBus.$off(VEHICLES_IMPORTED, this.updateTable);
  }
};
</script>
