<template>
  <div v-if="vehicleModel">
    <b-modal id="editModal" :title="oldVehicleRegistrationNumber" size="lg" @ok="editItem">
      <template>
        <b-container fluid>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="registrationNumber"> {{ $t("carControl.templateCarControl.registrationNumber") }}: </label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="registrationNumber"
                size="sm"
                v-model="vehicleModel.registrationNumber"
                :state="vehicleModel.registrationNumber !== ''"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="vin">{{ $t("carControl.templateCarControl.vin") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="vin"
                size="sm"
                v-model="vehicleModel.vin"
                :state="vehicleModel.vin !== ''"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="brand">{{ $t("carControl.templateCarControl.brand") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="brand"
                size="sm"
                v-model="vehicleModel.brand"
                :state="vehicleModel.brand !== ''"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="model">{{ $t("carControl.templateCarControl.model") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="model"
                size="sm"
                v-model="vehicleModel.model"
                :state="vehicleModel.model !== ''"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalYear">{{ $t("carControl.templateCarControl.year") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalYear"
                :placeholder="$t('carControl.templateCarControl.year')"
                v-model.number="vehicleModel.year"
                :state="checkPositiveNumberValue(vehicleModel.year)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalMileage">{{ $t("carControl.templateCarControl.mileage") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalMileage"
                :placeholder="$t('carControl.templateCarControl.mileage')"
                v-model="vehicleModel.mileage"
                :state="!vehicleModel.mileage || checkPositiveNumberValue(vehicleModel.mileage)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalFuelType">{{ $t("carControl.templateCarControl.fuel") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="addingModalFuelType"
                :placeholder="$t('carControl.templateCarControl.fuel')"
                v-model="vehicleModel.fuelType"
                :options="vehicleFuelTypeOptions"
                :state="checkSelectValue(vehicleModel.fuelType)"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="usageStartDate">{{ $t("carControl.templateCarControl.usageStartDate") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-input-group>
                <b-form-datepicker
                  id="usageStartDate"
                  v-model="vehicleModel.usageStartDate"
                  :locale="this.$i18n.locale"
                  :placeholder="$t('carControl.selectDate')"
                  :state="vehicleModel.usageStartDate !== null"
                ></b-form-datepicker>
                <b-button @click="vehicleModel.usageStartDate = null">{{ $t("dateTimePickerReset") }}</b-button>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="saleDate">{{ $t("carControl.templateCarControl.saleDate") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-input-group>
                <b-form-datepicker
                  id="saleDate"
                  v-model="vehicleModel.saleDate"
                  :locale="this.$i18n.locale"
                  :placeholder="$t('carControl.selectDate')"
                ></b-form-datepicker>
                <b-button @click="vehicleModel.saleDate = null">{{ $t("dateTimePickerReset") }}</b-button>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="category">{{ $t("carControl.templateCarControl.category") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="category"
                v-model="vehicleModel.category"
                :options="categories"
                :state="vehicleModel.category !== ''"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="type">{{ $t("carControl.templateCarControl.type") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="type"
                v-model="vehicleModel.type"
                :options="types"
                :state="checkSelectValue(vehicleModel.type)"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label>{{ $t("carControl.templateCarControl.template") }}:</label>
            </b-col>
            <b-col sm="9">
              {{ templateTitle }}
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-button v-b-modal.deleteConfirmationModal variant="danger">{{ $t("carControl.removeCarBtn") }}</b-button>
      <b-modal
        id="deleteConfirmationModal"
        :title="oldVehicleRegistrationNumber"
        @ok="deleteVehicle"
        ok-variant="danger"
        :ok-disabled="!canDeleteVehicle"
        :ok-title="$t('carControl.remove.ok')"
        @hidden="deleteRegMark = ''"
        :cancel-title="$t('carControl.remove.cancel')"
      >
        <div class="d-block text-center">
          <label for="deleteModalRegMark">
            <h3>{{ $t("carControl.deleteCarMsg") }}!</h3>
          </label>
        </div>
        <b-form-input v-model="deleteRegMark" :state="canDeleteVehicle" id="deleteModalRegMark"></b-form-input>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/api.js";
import { mapActions } from "vuex";
import eventBus from "@/services/event-bus.js";
import { VEHICLE_EDITED, VEHICLE_DELETED } from "@/const/events.js";
import * as VEHICLE_FUEL_TYPES from "@/const/vehicle-fuel-types.js";
import vehicleValidations from "@/mixins/vehicle-validations.js";

export default {
  mixins: [vehicleValidations],
  data() {
    return this.getDefaultData();
  },
  computed: {
    templateTitle() {
      return this.getTemplate(this.vehicleModel.category, this.vehicleModel.type);
    },
    canDeleteVehicle() {
      return this.oldVehicleRegistrationNumber === this.deleteRegMark;
    },
    vehicleFuelTypeOptions() {
      return [
        { value: VEHICLE_FUEL_TYPES.DIESEL, text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.DIESEL}`) },
        { value: VEHICLE_FUEL_TYPES.PETROL, text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.PETROL}`) },
        { value: VEHICLE_FUEL_TYPES.ELECTRIC, text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.ELECTRIC}`) },
        {
          value: VEHICLE_FUEL_TYPES.NOT_APPLICABLE,
          text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.NOT_APPLICABLE}`)
        }
      ];
    }
  },
  methods: {
    ...mapActions([
      "template/getActualTemplates",
      "vehicleType/getAll",
      "vehicleCategory/getAll",
      "vehicle/update",
      "vehicle/delete"
    ]),
    getDefaultData() {
      return {
        templates: [],
        types: [],
        categories: [],
        oldVehicleRegistrationNumber: "",
        vehicleModel: null,
        deleteRegMark: ""
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },
    getTemplate(category, type) {
      if (category && type) {
        let template = this.templates.find(x => x.vehicleCategory.id === category.id && x.vehicleType.id === type.id);

        if (template) return template.title;
      }

      return this.$t("carControl.error.not-found-template");
    },
    showEditControl(vehicleId) {
      this.loadData(vehicleId)
        .then(() => this.$bvModal.show("editModal"))
        .catch(() => alert(this.$t("carControl.error.while-loading-data-for-editing")));
    },
    loadData(vehicleId) {
      let vehicleEditModel = api.getVehicle(vehicleId).then(resp => {
        this.vehicleModel = resp.data;
        this.oldVehicleRegistrationNumber = this.vehicleModel.registrationNumber;
      });
      let actualTemplates = this["template/getActualTemplates"]().then(resp => (this.templates = resp.data));
      let allTypes = this["vehicleType/getAll"]().then(
        resp =>
          (this.types = resp.data.map(x => {
            return { text: x.title[this.$i18n.locale], value: x };
          }))
      );
      let allCategories = this["vehicleCategory/getAll"]().then(
        resp =>
          (this.categories = resp.data.map(x => {
            return { text: x.title[this.$i18n.locale], value: x };
          }))
      );
      return Promise.all([vehicleEditModel, actualTemplates, allTypes, allCategories]);
    },
    editItem(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.checkCarValidation(this.vehicleModel)) {
        alert(this.$t("carControl.error.validation-error"));
        return;
      }

      if (this.vehicleModel.saleDate) {
        if (this.vehicleModel.saleDate < this.vehicleModel.usageStartDate) {
          alert(this.$t("carControl.saleDateCheck"));
          return;
        }
      }

      this.updateVehicle(true, false);
    },
    updateVehicle(promptIsSoldExists, allowSameVinIfSold) {
      this["vehicle/update"]({ vm: this.vehicleModel, allowSameVinIfSold: allowSameVinIfSold })
        .then(() => {
          eventBus.$emit(VEHICLE_EDITED);
          this.$bvModal.hide("editModal");
        })
        .catch(err => {
          if (err.data.errorCode) {
            if (err.data.errorCode == "sold-vehicle-with-such-vin-already-exists") {
              if (promptIsSoldExists) {
                if (confirm(this.$t("carControl.error.sold-vehicle-with-such-vin-already-exists")))
                  this.updateVehicle(false, true);
              } else alert(this.$t("carControl.error.error-while-editing-vehicle"));
            } else alert(this.$t("carControl.error." + err.data.errorCode));
          } else {
            alert(this.$t("carControl.error.error-while-editing-vehicle"));
          }
        });
    },
    deleteVehicle() {
      this["vehicle/delete"]({ id: this.vehicleModel.id })
        .then(() => {
          eventBus.$emit(VEHICLE_DELETED);
          this.$bvModal.hide("deleteConfirmationModal");
          this.$bvModal.hide("editModal");
        })
        .catch(err => {
          if (err.data.errorCode) {
            alert(this.$t("carControl.error." + err.data.errorCode));
          } else {
            alert(this.$t("carControl.error.error-while-deleting-vehicle"));
          }
        });
    }
  }
};
</script>
