<template>
  <div>
    <b-button @click="onShowAddVehilceModalClick" class="mr-2 mb-2">{{ $t("carControl.addCar") }}</b-button>
    <b-modal
      id="adding-car"
      @ok="addItem"
      size="lg"
      :title="$t('carControl.add.title')"
      :ok-title="$t('carControl.add.ok')"
      :cancel-title="$t('carControl.add.cancel')"
    >
      <template>
        <form>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalRegistrationNumber">
                {{ $t("carControl.templateCarControl.registrationNumber") }}:
              </label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalRegistrationNumber"
                :placeholder="$t('carControl.templateCarControl.registrationNumber')"
                v-model="vehicleModel.registrationNumber"
                :state="checkInputValue(vehicleModel.registrationNumber)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalVin">{{ $t("carControl.templateCarControl.vin") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalVin"
                :placeholder="$t('carControl.templateCarControl.vin')"
                v-model="vehicleModel.vin"
                :state="checkInputValue(vehicleModel.vin)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalBrand">{{ $t("carControl.templateCarControl.brand") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalBrand"
                :placeholder="$t('carControl.templateCarControl.brand')"
                v-model="vehicleModel.brand"
                :state="checkInputValue(vehicleModel.brand)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalModel">{{ $t("carControl.templateCarControl.model") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalModel"
                :placeholder="$t('carControl.templateCarControl.model')"
                v-model="vehicleModel.model"
                :state="checkInputValue(vehicleModel.model)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalYear">{{ $t("carControl.templateCarControl.year") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalYear"
                :placeholder="$t('carControl.templateCarControl.year')"
                v-model.number="vehicleModel.year"
                :state="checkPositiveNumberValue(vehicleModel.year)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalMileage">{{ $t("carControl.templateCarControl.mileage") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="addingModalMileage"
                :placeholder="$t('carControl.templateCarControl.mileage')"
                v-model="vehicleModel.mileage"
                :state="!vehicleModel.mileage || checkPositiveNumberValue(vehicleModel.mileage)"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalFuelType">{{ $t("carControl.templateCarControl.fuel") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="addingModalFuelType"
                :placeholder="$t('carControl.templateCarControl.fuel')"
                v-model="vehicleModel.fuelType"
                :options="vehicleFuelTypeOptions"
                :state="checkSelectValue(vehicleModel.fuelType)"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalUsageStartDate">{{ $t("carControl.templateCarControl.usageStartDate") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-input-group>
                <b-form-datepicker
                  id="addingModalUsageStartDate"
                  value-as-date
                  :value="vehicleModel.usageStartDate"
                  :locale="this.$i18n.locale"
                  :placeholder="$t('carControl.selectDate')"
                  @input="x => (vehicleModel.usageStartDate = x)"
                  :state="vehicleModel.usageStartDate !== null"
                ></b-form-datepicker>
                <b-button @click="vehicleModel.usageStartDate = null">{{ $t("dateTimePickerReset") }}</b-button>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalSaleDate">{{ $t("carControl.templateCarControl.saleDate") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-input-group>
                <b-form-datepicker
                  id="addingModalSaleDate"
                  value-as-date
                  :value="vehicleModel.saleDate"
                  :locale="this.$i18n.locale"
                  @input="x => (vehicleModel.saleDate = x)"
                  :placeholder="$t('carControl.selectDate')"
                ></b-form-datepicker>
                <b-button @click="vehicleModel.saleDate = null">{{ $t("dateTimePickerReset") }}</b-button>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalCategory">{{ $t("carControl.templateCarControl.category") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="addingModalCategory"
                v-model="vehicleModel.category"
                :options="categories"
                :state="checkSelectValue(vehicleModel.category)"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="addingModalType">{{ $t("carControl.templateCarControl.type") }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                id="addingModalType"
                v-model="vehicleModel.type"
                :options="types"
                :state="checkSelectValue(vehicleModel.type)"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col b-col sm="3">
              <label>{{ $t("carControl.templateCarControl.template") }}:</label>
            </b-col>
            <b-col sm="9">
              {{ templateTitle }}
            </b-col>
          </b-row>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import eventBus from "@/services/event-bus.js";
import { VEHICLE_ADDED } from "@/const/events.js";
import * as VEHICLE_FUEL_TYPES from "@/const/vehicle-fuel-types.js";
import { mapActions } from "vuex";
import vehicleValidations from "@/mixins/vehicle-validations.js";

export default {
  mixins: [vehicleValidations],
  data() {
    return this.getDefaultData();
  },
  computed: {
    templateTitle() {
      return this.getTemplate(this.vehicleModel.category, this.vehicleModel.type);
    },
    vehicleFuelTypeOptions() {
      return [
        { value: VEHICLE_FUEL_TYPES.DIESEL, text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.DIESEL}`) },
        { value: VEHICLE_FUEL_TYPES.PETROL, text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.PETROL}`) },
        { value: VEHICLE_FUEL_TYPES.ELECTRIC, text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.ELECTRIC}`) },
        {
          value: VEHICLE_FUEL_TYPES.NOT_APPLICABLE,
          text: this.$t(`vehicle-fuel-type.${VEHICLE_FUEL_TYPES.NOT_APPLICABLE}`)
        }
      ];
    }
  },
  methods: {
    ...mapActions(["vehicle/add", "template/getActualTemplates", "vehicleType/getAll", "vehicleCategory/getAll"]),
    getDefaultData() {
      return {
        templates: [],
        types: [],
        categories: [],
        vehicleModel: {
          registrationNumber: "",
          vin: "",
          brand: "",
          model: "",
          usageStartDate: null,
          saleDate: null,
          category: null,
          type: null,
          year: null,
          mileage: null,
          fuelType: null
        }
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },
    getTemplate(category, type) {
      if (category && type) {
        var template = this.templates.find(
          x =>
            x.vehicleCategory != null &&
            x.vehicleCategory.id === category.id &&
            x.vehicleType != null &&
            x.vehicleType.id === type.id
        );

        if (!template) {
          template = this.templates.find(
            x => x.vehicleCategory == null && x.vehicleType != null && x.vehicleType.id === type.id
          );
        }

        if (!template) {
          template = this.templates.find(
            x => x.vehicleCategory != null && x.vehicleCategory.id === category.id && x.vehicleType == null
          );
        }

        if (template) return template.title;
      }

      return this.$t("carControl.error.not-found-template");
    },
    onShowAddVehilceModalClick() {
      this.reset();
      let actualTemplates = this["template/getActualTemplates"]().then(resp => (this.templates = resp.data));
      let allTypes = this["vehicleType/getAll"]().then(
        resp =>
          (this.types = resp.data.map(x => {
            return { text: x.title[this.$i18n.locale], value: x };
          }))
      );
      let allCategories = this["vehicleCategory/getAll"]().then(
        resp =>
          (this.categories = resp.data.map(x => {
            return { text: x.title[this.$i18n.locale], value: x };
          }))
      );

      Promise.all([actualTemplates, allTypes, allCategories])
        .then(() => this.$bvModal.show("adding-car")) // all data is loaded - we can show modal
        .catch(() => alert(this.$t("carControl.error.while-loading-data-for-adding")));
    },
    addItem(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.vehicleModel.saleDate !== null && this.vehicleModel.saleDate < this.vehicleModel.usageStartDate)
        alert(this.$t("carControl.saleDateCheck"));
      else if (this.checkCarValidation(this.vehicleModel)) {
        this.addVehicle(true, false);
      } else {
        alert(this.$t("carControl.error.validation-error"));
      }
    },
    addVehicle(promptIsSoldExists, allowSameVinIfSold) {
      const vm = {
        ...this.vehicleModel,
        year: Number(this.vehicleModel.year),
        mileage: this.vehicleModel.mileage == null ? null : Number(this.vehicleModel.mileage)
      };
      this["vehicle/add"]({ vm, allowSameVinIfSold: allowSameVinIfSold })
        .then(() => {
          eventBus.$emit(VEHICLE_ADDED);
          this.$bvModal.hide("adding-car");
        })
        .catch(err => {
          if (err.data.errorCode) {
            if (err.data.errorCode == "sold-vehicle-with-such-vin-already-exists") {
              if (promptIsSoldExists) {
                if (confirm(this.$t("carControl.error.sold-vehicle-with-such-vin-already-exists")))
                  this.addVehicle(false, true);
              } else alert(this.$t("carControl.error.error-while-adding-vehicle"));
            } else alert(this.$t("carControl.error." + err.data.errorCode));
          } else {
            alert(this.$t("carControl.error.error-while-adding-vehicle"));
          }
        });
    }
  }
};
</script>
